<template>
  <div class="Header">
    
	<div class="Wrap">
		
		<div class="Left">
		</div>
		
		<div class="Right">
			
			<div class="Search">
				<el-input placeholder="请输入内容" v-model="SearchInput" class="input-with-select">
				   
				    <el-button slot="append" icon="el-icon-search" @click="ShowTips()">搜索</el-button>
				  </el-input>
			</div>
			
			<div class="Nav">
				<el-button slot="append" icon="el-icon-shopping-cart-2" style="background-color: #D30701;border-color: #D30701;color: #FFFFFF;" @click="Block('/shoppingcart')">购物车</el-button>
			</div>
			
		</div>
		
	</div>
	
  </div>
</template>

<script>
	import {  Button,Input,Select,Option,OptionGroup } from 'element-ui';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
      return {
        SearchPlatform: 0,
		SearchInput:''
      }
    },
  components: {
  	  "el-button":Button,
	  "el-input":Input,
	  
  },
  methods:{
	  ShowTips(){
		  this.$Jump('/login')
	  },
	  Block(_url){
		if(this.$store.getters.getToken == ''){
			this.$Jump('/login')
			return
		}
		this.$Jump(_url)
	  },
  }
}
</script>

<style scoped>
.Header{
	height: 150px;
	background-color: #FFFFFF;
	border-bottom: 1px solid rgba(0,0,0,0.08);
}
.Header .Wrap .Left{
	width: 300px;
	display: flex;
	align-items: center;
	height: 150px;
}
.Logo{
	cursor: pointer;
}
.Header .Wrap .Right{
	flex: 1;
	display: flex;
}
.Header .Wrap .Right .Search{
	width: 450px;
	height: 150px;
	display: flex;
	align-items: center;
}
.Header .Wrap .Right .Nav{
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}
</style>
