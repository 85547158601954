<template>
  <div class="Footer">
    
	<div class="Wrap">
		
		<div class="Navs">
			<span @click="$Jump('/login')">关于以通</span>
			|
			<span @click="$Jump('/login')">联系我们</span>
			|
			<span @click="$Jump('/login')">法律声明</span>
			|
			<span @click="$Jump('/login')">隐私政策</span>
			|
			<span @click="$Jump('/login')">采购指南</span>
		</div>
		
		<div class="CopyRight">© 2018-2020 {{$store.getters.getAppName}} 版权所有 闽ICP备18019047号-1</div>

		
	</div>
	
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  components: {
  },
}
</script>

<style scoped>
.Footer{
	padding: 50px 0px 30px;
	background-color: #FFFFFF;
	text-align: center;
}
.Footer .Wrap{
	flex-wrap: wrap;
}
.Footer .Wrap div{
	width: 100%;
}
.Navs{
	margin-bottom: 10px;
}
.Navs span{
	margin-left: 20px;
	margin-right: 20px;
	cursor: pointer;
}
.Navs span:hover{
	color: #D30701;
}
.CopyRight{
	color: #888888;
	margin-bottom: 10px;
}
</style>
