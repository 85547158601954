<template>
<div>
</div>
</template>

<script>
	import TopNav from '@/components/Top.vue'
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import {  Carousel,CarouselItem,Message } from 'element-ui';
	export default {
	  name: 'Index',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
	        GoodsRecommend: [],
			CategoryList:[],
			NewsList:[],
	      }
	    },
	  components: {
	  	  TopNav,
		  Header,
		  Footer,
		  "el-carousel":Carousel,
		  "el-carousel-item":CarouselItem
	  },
	  created() {
	  	this.$Jump('/login')
	  },
	  methods:{
		  Block(_url){
			if(this.$store.getters.getToken == ''){
				this.$Jump('/login')
				return
			}
			this.$Jump(_url)
		  },
		  GetCategoryList(_page){
		  		let that = this
		  		let data = {
		  			Service:'yt.ec',
		  			Class: 'Category',
		  			Action: 'List',
		  			Page:_page,
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((response) => {
		  			
		  			if(response.ErrorId != 0){
		  				Message({
		  						  message: response.Msg,
		  						  type: 'error'
		  						});
		  						
		  				return
		  			}
		  	
		  			that.CategoryList = response.Data
		  			
		  			
		  		})
		  		.catch(function (response) {
		  			that.DialogMsg = '网络请求错误'
		  		})
		  },
		  GetNewsList(_page){
		  		let that = this
		  		let data = {
		  			Service:'yt.news',
		  			Class: 'News',
		  			Action: 'IndexRecommend',
		  			Page:_page,
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((response) => {
		  			
		  			if(response.ErrorId != 0){
		  				Message({
		  						  message: response.Msg,
		  						  type: 'error'
		  						});
		  						
		  				return
		  			}
		  	
		  			that.NewsList = response.Data
		  			
		  			
		  		})
		  		.catch(function (response) {
		  			that.DialogMsg = '网络请求错误'
		  		})
		  },
		  Recommend(_page){
		  		let that = this
		  		let data = {
		  			Service:'yt.ec',
		  			Class: 'Goods',
		  			Action: 'Recommend',
		  			Page:_page,
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((response) => {
		  			
		  			if(response.ErrorId != 0){
		  				Message({
						  message: response.Msg,
						  type: 'error'
						});
		  						
		  				return
		  			}
		  	
		  			that.GoodsRecommend = response.Data
		  			
		  			
		  		})
		  		.catch(function (response) {
		  			that.DialogMsg = '网络请求错误'
		  		})
		  }
	  }
	}
</script>

<style scoped>
	
.Area1{
	background-color: #d30701;
	height: 350px;
}
.Area1 .Left{
	width: 850px;
	height: 350px;
}
.Area1 .Right{
	flex: 1;
	background-color: #FFFFFF;
}
.Area1 .Right .Nav{
	display: flex;
	flex-wrap: wrap;
}
.Area1 .Right .Nav ul{
	width: 50%;
	height: 80px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	font-size: 1.1rem;
	cursor: pointer;
}
.Area1 .Right .Nav ul span{
	margin-left: 20px;
	margin-right: 10px;
	font-size: 1.5rem;
	color: rgba(0,0,0,0.2);
}
.Area1 .Right .Nav ul:hover{
	color: #D30701;
}
.Area1 .Right .Nav ul:hover span{
	color: #D30701;
}
.Area1 .Right .Nav ul.R{
	border-left: 1px solid rgba(0,0,0,0.05);
}
.Area1 .Right .News{ /*188*/
	height: 155px;
	padding-top: 18px;
}
.Area1 .Right .News li{
	height: 30px;
	line-height: 30px;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.Area1 .Right .News li:hover{
	color: #D30701;	
}
.Area1 .Right .News li.N{
	border-bottom: 1px solid rgba(0,0,0,0);
}

.Recoment{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.Recoment div{
	width: 33.333333%;
	cursor: pointer;
}
.Recoment div img{
	width: 100%;
}

.AreaGuide{
	width: 100%;
	text-align: center;
}
.AreaGuide h3{
	height: 30px;
	line-height: 30px;
	font-size: 1.4rem;
	margin-bottom: 0px;
	
}
.AreaGuide p{
	height: 20px;
	line-height: 20px;
	color: #999999;
}
.Area3{
	background-color: #FFFFFF;
	margin-top: 10px;
	padding-top: 40px;
	padding-bottom: 50px;
}
.Area3 .Wrap{
	flex-wrap: wrap;
}
.CategoryList{
	width: 100%;
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}
.CategoryList li{
	width: 20%;
	text-align: center;
	margin-bottom:30px;
}
.CategoryList li span{
	display: inline-block;
	border:1px solid #ffffff;
	cursor: pointer;
	width: 60px;
}
.CategoryList li span div{
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.CategoryList li span img{
	width: 38px;
	height: 38px;
}
.CategoryList li span:hover{
}
.CategoryList li label{
	display: block;
	cursor: pointer;
	margin-top: 10px;
	color: #666666;
	width: 100%;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
}
.CategoryList li span:hover label{
	color: #D30701;
}

.Area4{
	margin-top: 10px;
	padding-top: 20px;
}
.Area4 .Wrap{
	flex-wrap: wrap;
}
.GoodsList{
	background-color: #FFFFFF;
	width: 1200px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
	margin-bottom: 30px;
}
.GoodsList .More{
	padding: 40px 0px;
	width: 100%;
	display: block;
	text-align: center;
}
.GoodsList .More span{
	width: 200px;
	text-align: center;
	height: 30px;
	line-height: 30px;
	border-radius: 15px;
	border:1px solid #D30701;
	display:inline-block;
	color:#D30701;
	cursor: pointer;
}
.GoodsList .More span:hover{
	color:#ffffff;
	background:#D30701;
}
.Goods{
	width: 200px;
	padding: 30px 20px;
	height: 280px;
	text-align: center;
	position: relative;
	border-bottom: 1px solid rgba(0,0,0,0.06);
}
.Goods .BR{
	position: absolute;
	height: 100%;
	width: 1px;
	background-color: rgba(0,0,0,0.06);
	right: 0px;
	top: 0px;
}
.Goods:hover{
	cursor: pointer;
}
.Goods img{
	width: 200px;
	max-height: 200px;
}
.Goods li{
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	color: #666666;
	margin-top: 5px;
	text-align: left;
}
.Goods .Price{
	position: absolute;
	height: 20px;
	left: 20px;
	bottom: 30px;
	color: #D30701;
	font-size: 12px;
}
.Goods .Price em{
	font-size: 1.4rem;
}
.Goods .Compare{
	position: absolute;
	height: 20px;
	right: 20px;
	bottom: 25px;
	width: 50px;
	height: 20px;
	line-height: 20px;
	border-radius: 3px;
	border: 1px solid #D30701;
	color: #D30701;
	cursor: pointer;
}
.Goods .Compare:hover{
	background-color: #D30701;
	color: #FFFFFF;
}
.Goods .Cart{
	position: absolute;
	height: 20px;
	right: 20px;
	top: 30px;
	width: 120px;
	height: 20px;
	line-height: 20px;
	border-radius: 3px;
	border: 1px solid #D30701;
	color: #D30701;
	cursor: pointer;
	background-color: rgba(255,255,255,0.8);
	display: none;
}
.Goods:hover .Cart{
	display: block;
}
</style>
