<template>
  <div class="Top">
    
	<div class="Wrap">
		<div class="Home">
				<i class="el-icon-s-home" @click="$Jump('/')">{{$store.getters.getAppName}}</i>
		</div>
		
		<div class="Nav">
			<span class="Welcome">您好，欢迎访问</span>
			<i size="mini" @click="$Jump('/login')" v-if="$store.getters.getToken == ''">登录</i>
			<i size="mini" @click="$Jump('/register')" v-if="$store.getters.getToken == ''">注册</i>
			
			<i size="mini" @click="$Jump('/my')" v-if="$store.getters.getToken != ''">{{$store.getters.getUserName}}</i>
			
			<i size="mini" @click="Logout()" v-if="$store.getters.getToken != ''">退出</i>
			
		</div>
	</div>
	
  </div>
</template>

<script>
	import {  Button } from 'element-ui';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  components: {
  	  //"el-button":Button,
  },
  methods:{
	  Logout(){
		  this.$store.dispatch('setToken','')
		  this.$store.dispatch('setUserName','游客')
	  }
  }
}
</script>

<style scoped>
.Top{
	background-color: #d30701;
	display: flex;
	height: 40px;
	line-height: 40px;
	color: #ffffff;
}
.Top .Home{
	width: 100px;
}
.Top .Home i{
	cursor: pointer;
}
.Top .Nav{
	flex: 1;
	text-align: right;
}
.Top .Nav i{
	padding-left: 15px;
	border-left: 1px solid rgba(255,255,255,0.4);
	margin-left: 15px;
	font-style: normal;
	cursor: pointer;
}
.Top .Nav i:hover{
	text-decoration: underline;
}
</style>
